@font-face {
    font-family: 'Unimed';
    font-style: normal;
    font-weight: normal;
    src: local('UnimedSlab-Regular.ttf'), url('UnimedSlab-Regular.ttf');
}

@font-face {
    font-family: 'UnimedBold';
    font-style: normal;
    font-weight: normal;
    src: local('UnimedSlab-SemiBold.ttf'), url('UnimedSlab-SemiBold.ttf');
}

.divPrincipal {
    height: 100vh;
    width: 100vw;
}

.centralizar {
    margin: auto;
    width: 80%;
}

.GridLogo {
    width: 40%;
    height: 100px;
    background-image: linear-gradient(#b6ff71f3, rgb(112, 167, 71, 1));
    border-bottom: 2px solid rgb(130, 136, 104);
}

.GridHome {
    height: 500px;
}

.GridFiltros {
    border: 2px solid rgb(130, 136, 104);
    border-radius: 0px 0px 5px 5px;
    border-top: none !important;
}

.selectFiltro {
    text-align: center;
    height: 40px;
    width: 150px;
}

.GridChamados {
    padding: 10px;
    border: 2px solid rgb(130, 136, 104);
    border-radius: 0px 0px 5px 5px;
    border-top: none !important;
}

.GridChamado {
    height: 140px !important;
    padding: 10px;
    border: 2px solid rgb(130, 136, 104);
    border-radius: 5px;
    background: rgb(245, 239, 231);
}

.GridChamadoNaoVisto {
    height: 140px !important;
    padding: 10px;
    border: 2px solid rgb(130, 136, 104);
    border-radius: 5px;
    background: rgb(241, 198, 142, 0.6);
}

.GridInfoChamado {
    height: 140px !important;
    padding: 10px;
    border-radius: 5px;
}

.tipographyTitulo {
    font-family: 'UnimedBold' !important;
}

.tipographyTexto {
    font-family: 'UnimedBold' !important;
}

.labelInput {
    font-family: 'UnimedBold' !important;
}

.button {
    font-family: 'UnimedBold' !important;
}